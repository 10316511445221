import React from 'react'
import { Builder } from '@builder.io/react'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import ProvenQuizFirstQuestion from '../../../shared/proven-quiz-first-question'
import { COMBO_PRODUCTS__KEYWORDS, KEYWORD_SKIN } from '../../../../constants/funnels'
import { getQuestionnaireInfoByProductKeyword } from '../../../../utils/questionnaire-configurator'

export const ProvenQuizFirstQuestionForBuilder = ({ quiz, ...props }) => {
  return (
    <ProvenResponsiveContainer>
      <ProvenQuizFirstQuestion
        questionnaireId={getQuestionnaireInfoByProductKeyword(quiz).id}
        {...props}
      />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(ProvenQuizFirstQuestionForBuilder, {
  name: 'Quiz First Question',
  inputs: [
    { name: 'title', type: 'text' },
    { name: 'subtitle', type: 'text' },
    { name: 'imageBackgroundMobilePath', type: 'file' },
    { name: 'imageBackgroundDesktopPath', type: 'file' },
    {
      name: 'quiz',
      type: 'text',
      enum: COMBO_PRODUCTS__KEYWORDS,
      defaultValue: KEYWORD_SKIN,
      helperText: 'Select skin quiz, serum or eye quiz'
    }
  ]
})
