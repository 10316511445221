import React from 'react'
import { Builder } from '@builder.io/react'
import { Typography } from '@mui/material'
import {
  prepareTypographyH1,
  prepareTypographyH3,
  prepareTypographyH4,
  prepareTypographyHyperlinkStyle,
  prepareTypographyTitle2
} from 'styles/theme-proven/typography'
import { onDesktop } from 'styles/theme-proven/responsive'
import { Text as BuilderText } from '@builder.io/react'
import { createProvenTheme } from 'styles/theme-proven'
import { useBuilderStyles } from 'hooks/useBuilderStyles'
import { brandTextColors } from 'styles/theme-proven/colors'
import { allFontTypes } from 'styles/theme-proven/typography'

const theme = createProvenTheme()

const sxOverrides = {
  default: {},
  blogPost: {
    h1: {
      ...prepareTypographyH3(theme),
      [onDesktop]: {
        ...prepareTypographyH1(theme)
      },
      textTransform: 'none'
    },
    h2: {
      ...prepareTypographyH4(theme),
      marginBottom: 3,
      marginTop: 4,
      [onDesktop]: {
        marginTop: 8
      }
    },
    h3: {
      ...prepareTypographyTitle2(theme),
      marginBottom: 3,
      marginTop: 3
    },
    'ol, ul, p': {
      marginBottom: 3,
      marginTop: 3
    },
    strong: {
      fontWeight: 800
    },
    a: prepareTypographyHyperlinkStyle(theme)
  }
}

export const Text = ({ variant, target, color, component, text, ...props }) => {
  const builderStyles = useBuilderStyles(props.builderBlock, [
    'textTransform',
    'textAlign',
    'fontSize',
    'lineHeight'
  ])

  if (variant !== 'none') {
    delete builderStyles.fontSize
    delete builderStyles.lineHeight
  }

  return (
    <Typography
      data-testid="builder-io-text-overwrite-testid"
      variant={variant === 'none' ? null : variant}
      color={color}
      component={component}
      sx={{
        ...(sxOverrides[target] || {}),
        ...builderStyles
      }}
    >
      <BuilderText text={text} builderBlock={props.builderBlock} />
    </Typography>
  )
}

export const textConfig = {
  name: 'Text',
  override: true,
  inputs: [
    {
      name: 'variant',
      type: 'text',
      enum: [...allFontTypes, 'none'],
      defaultValue: 'body1'
    },
    {
      name: 'color',
      type: 'text',
      enum: brandTextColors
    },
    {
      name: 'text',
      type: 'html',
      required: true,
      autoFocus: true,
      bubble: true,
      defaultValue: 'Enter some text...'
    },
    {
      name: 'target',
      type: 'text',
      enum: [
        { label: 'Default', value: 'default' },
        { label: 'Blog post', value: 'blogPost' }
      ],
      defaultValue: 'default',
      helperText: 'Where this text will be used; determines styling overrides.'
    },
    {
      name: 'component',
      type: 'text',
      enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'span', 'div'],
      defaultValue: 'p'
    }
  ]
}

Builder.registerComponent(Text, textConfig)
