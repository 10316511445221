import React from 'react'
import { Builder } from '@builder.io/react'
import Influencer from 'components/shared/influencers/influencer'

const InfluencerCard = ({ influencer }) => {
  const data = influencer?.data?.data || influencer?.value?.data || null

  return (
    <Influencer
      name={data?.name || 'Lorem Ipsum'}
      focus={data?.pullQuote || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'}
      portfolioImage={data?.avatar || 'https://placehold.co/40x40'}
      {...data}
    />
  )
}

Builder.registerComponent(InfluencerCard, {
  name: 'Influencer Card',
  inputs: [
    {
      name: 'influencer',
      type: 'reference',
      model: 'influencer',
      defaultValue: {
        '@type': '@builder.io/core:Reference',
        id: 'dd43d32cf0bc43c2bc5f88800a106c14',
        model: 'influencer'
      }
    }
  ]
})
