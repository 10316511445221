import { Builder } from '@builder.io/react'
import ScientificCitations from 'components/pages/blog/builder-blog-post/scientific-citations'

Builder.registerComponent(ScientificCitations, {
  name: 'Scientific Citations',
  inputs: [
    { name: 'title', type: 'text' },
    {
      name: 'scientificNotations',
      type: 'list',
      subFields: [
        { name: 'scientificNotation', type: 'text' },
        { name: 'link', type: 'text' }
      ]
    }
  ]
})
