import React from 'react'
import { Builder, withChildren } from '@builder.io/react'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import { useBuilderStyles } from 'hooks/useBuilderStyles'

export const ResponsiveContainerForBuilder = withChildren(
  ({
    children,
    backgroundColor,
    minHeight,
    fitSidePaddingsForDesktopDashboard,
    disableLeftPadding,
    disableRightPadding,
    builderBlock
  }) => {
    const builderStyles = useBuilderStyles(builderBlock, [
      'background',
      'backgroundColor',
      'backgroundImage',
      'minHeight'
    ])

    return (
      <ProvenResponsiveContainer
        backgroundColor={backgroundColor}
        minHeight={minHeight}
        fitSidePaddingsForDesktopDashboard={fitSidePaddingsForDesktopDashboard}
        disableLeftPadding={disableLeftPadding}
        disableRightPadding={disableRightPadding}
        sx={builderStyles}
      >
        {children}
      </ProvenResponsiveContainer>
    )
  }
)

Builder.registerComponent(ResponsiveContainerForBuilder, {
  name: 'Responsive Container',
  canHaveChildren: true,
  inputs: [
    {
      name: 'backgroundColor',
      type: 'text'
    },
    {
      name: 'minHeight',
      type: 'text'
    },
    {
      name: 'fitSidePaddingsForDesktopDashboard',
      type: 'boolean',
      defaultValue: false
    },
    { name: 'diableLeftPadding', type: 'boolean' },
    { name: 'disableRightPadding', type: 'boolean' }
  ]
})
