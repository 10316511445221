import React from 'react'
import { Builder } from '@builder.io/react'
import FiveStarsRating from '../../../shared/five-stars-rating'
import { brandTextColors } from '../../../../styles/theme-proven/colors'

export const ReviewStarsForShop = ({ rating, color, size }) => (
  <FiveStarsRating rating={rating} size={size} color={color} />
)

const builderConfig = {
  inputs: [
    { name: 'rating', type: 'number', defaultValue: 4.5 },
    { name: 'color', type: 'text', enum: brandTextColors, defaultValue: 'primary.main' },
    { name: 'size', type: 'number', defaultValue: 24 }
  ]
}

Builder.registerComponent(ReviewStarsForShop, {
  name: 'Review Stars for Shop',
  ...builderConfig
})
