import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authSelector } from '../../../utils/selectors'
import propTypes from 'prop-types'
import { signinSuccess } from '../../../actions/auth0.actions'
import { pathOr } from 'ramda'

class AuthUser extends Component {
  componentDidMount() {
    const { auth, queryString } = this.props

    let expiresAt = JSON.stringify(auth.expires_in * 1000 + new Date().getTime())
    localStorage.setItem('access_token', auth.access_token)
    localStorage.setItem('id_token', auth.id_token)
    localStorage.setItem('expires_at', expiresAt)
    this.props.signinSuccess()
    this.props.history.push(`/account/congratulations${queryString}`)
  }

  render() {
    return <div />
  }
}

const mapStateToProps = state => {
  const queryString = pathOr('', ['auth', 'queryString'], state)
  return {
    auth: authSelector(state),
    queryString: queryString
  }
}

const mapDispatchToProps = dispatch => ({
  signinSuccess: () => dispatch(signinSuccess())
})

AuthUser.propTypes = {
  auth: propTypes.object,
  history: propTypes.object,
  signinSuccess: propTypes.func,
  queryString: propTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthUser)
