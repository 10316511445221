import React from 'react'
import { Builder } from '@builder.io/react'
import ProvenButtonWithIntent from 'components/shared/proven-button-with-intent'
import { builderConfig } from './Button'
import { useBuilderSharedData } from 'hooks/useBuilderSharedData'
import useAuth from '../../../../hooks/use-auth'

const ButtonWithIntent = ({
  text: textFromBuilder,
  color,
  variant,
  intent,
  fullWidth,
  gift,
  customColor,
  fontFamily,
  fontSize,
  letterSpacing,
  fontWeight,
  backgroundColor,
  authenticatedText,
  unauthenticatedText
}) => {
  const { reactOnly } = useBuilderSharedData()
  const { isAuthenticated } = useAuth()
  let text
  if (authenticatedText && isAuthenticated) {
    text = authenticatedText
  } else if (unauthenticatedText && !isAuthenticated) {
    text = unauthenticatedText
  } else {
    text = textFromBuilder || reactOnly?.pdp?.defaultUnauthorizedButtonText
  }
  return (
    <ProvenButtonWithIntent
      text={text}
      color={color}
      variant={variant}
      intent={intent}
      fullWidth={fullWidth}
      gift={gift}
      sx={{
        ...(fontFamily && { fontFamily }),
        ...(customColor && { color: customColor }),
        ...(fontSize && { fontSize: `${fontSize} !important` }),
        ...(letterSpacing && { letterSpacing }),
        ...(fontWeight && { fontWeight }),
        ...(backgroundColor && { backgroundColor })
      }}
    />
  )
}

Builder.registerComponent(ButtonWithIntent, {
  ...builderConfig,
  name: 'Button With Intent',
  inputs: [
    ...builderConfig.inputs.filter(({ name }) => name !== 'color'),
    { name: 'gift', type: 'boolean', defaultValue: false },
    { name: 'intent', type: 'text', enum: ['skin', 'eye'] },
    {
      name: 'backgroundColor',
      type: 'text',
      required: false,
      helperText: 'The button color in hexadecimal format. Example: #EFC'
    },
    {
      name: 'fontFamily',
      type: 'text',
      required: false,
      helperText: 'The font name used. Example: Elvetica'
    },
    {
      name: 'fontSize',
      type: 'text',
      required: false,
      helperText: 'The font size used. Example: 14px'
    },
    {
      name: 'letterSpacing',
      type: 'text',
      required: false,
      helperText: 'The space between letters. Example: 10px'
    },
    {
      name: 'fontWeight',
      type: 'text',
      enum: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
      required: false,
      helperText: 'The font weight'
    },
    {
      name: 'color',
      type: 'text',
      enum: ['primary', 'secondary', 'inherit'],
      defaultValue: 'secondary'
    },
    {
      name: 'customColor',
      type: 'text',
      required: false,
      helperText:
        'The text button color in hexadecimal format. Example: #EFC. If it is set, this value overwrites the color option'
    },
    {
      name: 'authenticatedText',
      type: 'text',
      required: false,
      helperText:
        'Button text to show when user is logged-in. This value overwrites the default text'
    },
    {
      name: 'unauthenticatedText',
      type: 'text',
      required: false,
      helperText:
        'Button text to show when user is not logged-in. This value overwrites the default text'
    }
  ]
})
