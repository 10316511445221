import React from 'react'
import { Builder } from '@builder.io/react'
import SephoraInStoreCover from '../../../shared/sephora-in-store-cover'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'

export const SephoraInStoreCoverForBuilder = props => {
  return <SephoraInStoreCover {...props} />
}

Builder.registerComponent(SephoraInStoreCoverForBuilder, {
  name: 'Sephora In-Store Cover',
  inputs: [
    { name: 'title', type: 'text' },
    { name: 'subTitle', type: 'richText' },
    { name: 'buttonText', type: 'text' },
    { name: 'beautyAdvisorOptional', type: 'text' },
    { name: 'beautyAdvisorDescription', type: 'text' },
    { name: 'beautyAdvisorInputPlaceholder', type: 'text' }
  ]
})
