import React from 'react'
import { Builder } from '@builder.io/react'
import CountdownTimer from '../../../shared/countdown-timer'

const Countdown = ({ date }) => <CountdownTimer date={date} />

Builder.registerComponent(Countdown, {
  name: 'Countdown Timer',
  inputs: [
    {
      name: 'date',
      type: 'date',
      required: true
    }
  ]
})
