import React from 'react'
import { Builder } from '@builder.io/react'
import GiftCertificate from '../../../shared/gift-certificate'

const GiftCertificateForBuilder = () => {
  return <GiftCertificate />
}

Builder.registerComponent(GiftCertificateForBuilder, {
  name: 'Gift Certificate',
  inputs: []
})
