import React from 'react'
import { Builder, withChildren } from '@builder.io/react'
import { Box } from '@mui/material'
import { useBuilderStyles } from 'hooks/useBuilderStyles'

import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../../styles/theme-proven'

const themeProven = createProvenTheme()

// A grid that conforms to our style guide.
const ResponsiveGrid = withChildren(props => {
  const builderStyles = useBuilderStyles(props.builderBlock, [
    'gridAutoFlow',
    'gridAutoRows',
    'gridTemplateAreas',
    'gridTemplateRows',
    'rowGap',
    'height',
    'minHeight',
    'width',
    'maxWidth',
    'alignItems',
    'alignContent',
    'justifyItems',
    'justifyContent'
  ])

  let columnTemplateToUseInMd
  switch (props.gridTemplateColumnsForTablet) {
    case 'styleGuideGridForMobile':
      columnTemplateToUseInMd = 'repeat(4, 1fr)'
      break
    case 'styleGuideGridForDesktop':
      columnTemplateToUseInMd = 'repeat(12, 1fr)'
      break
    case 'styleGuideGridForTablet':
    default:
      columnTemplateToUseInMd = 'repeat(8, 1fr)'
  }

  return (
    <ThemeProvider theme={themeProven}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(4, 1fr)',
            md: columnTemplateToUseInMd,
            lg: 'repeat(12, 1fr)'
          },
          columnGap: '24px',
          // It's configured the themeProven breakpoints (only xs, md and lg)
          // React breakpoints with Builder breakpoints.
          // https://proven-data.slack.com/archives/C047M74AE03/p1672095097658979

          ...builderStyles
        }}
      >
        {props.children}
      </Box>
    </ThemeProvider>
  )
})

Builder.registerComponent(ResponsiveGrid, {
  name: 'Responsive Grid',
  canHaveChildren: true,
  inputs: [
    {
      name: 'gridTemplateColumnsForTablet',
      type: 'text',
      enum: ['styleGuideGridForDesktop', 'styleGuideGridForTablet', 'styleGuideGridForMobile'],
      defaultValue: 'styleGuideGridForTablet',
      helperText: `The grid template columns to be used in md resolution. Default value: 'styleGuideGridForTablet' ( 'repeat(8, 1fr)' )`
    }
  ]
})
