import { Builder } from '@builder.io/react'
import BeforeAfterCarousel from '../../../shared/before-after-carousel'

Builder.registerComponent(BeforeAfterCarousel, {
  name: 'Before After Carousel',
  inputs: [
    { name: 'title', type: 'text' },
    { name: 'showClinicalProofLink', type: 'boolean' }
  ]
})
