import React from 'react'
import { Builder } from '@builder.io/react'
import { Typography } from '@mui/material'
import { brandTextColors } from 'styles/theme-proven/colors'
import { allFontTypes } from 'styles/theme-proven/typography'

// DEPRECATED
const TitleStyled = ({ text, variant, color, component }) => (
  <Typography variant={variant} color={color} component={component}>
    {text}
  </Typography>
)

Builder.registerComponent(TitleStyled, {
  name: 'Title',
  inputs: [
    {
      name: 'text',
      type: 'text',
      defaultValue: 'Title...'
    },
    {
      name: 'variant',
      type: 'text',
      enum: allFontTypes,
      defaultValue: 'h1'
    },
    {
      name: 'color',
      type: 'text',
      enum: brandTextColors
    },
    {
      name: 'component',
      type: 'text',
      enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'span', 'div'],
      defaultValue: 'p'
    }
  ],
  hideFromInsertMenu: true
})
