import React from 'react'
import { Builder } from '@builder.io/react'
import SubscribeAndThriveSystem from '../../../shared/subscribe-and-thrive-system'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'

export const SubscribeAndThriveForBuilder = props => {
  return (
    <ProvenResponsiveContainer>
      <SubscribeAndThriveSystem {...props} />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(SubscribeAndThriveForBuilder, {
  name: 'Subscribe And Thrive',
  inputs: [
    {
      name: 'addPriceInButtonWhenIsAuthenticated',
      type: 'boolean',
      defaultValue: false,
      helperText: `Add the corresponding price at the end of the button's text, only when user is authenticated`
    }
  ]
})
