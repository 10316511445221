import React from 'react'
import { Builder } from '@builder.io/react'
import ProvenTextField from 'components/shared/proven-text-field'
import { TYPE_KIT, TYPE_SYSTEM, TYPE_TAB, TYPE_DASHBOARD, TYPE_SOCIAL } from 'constants/icons'
import { brandTextColors } from 'styles/theme-proven/colors'

const TextField = ({
  type,
  name,
  required,
  label,
  color,
  disabled,
  error,
  placeholder,
  value,
  isThereAnUnknownError,
  iconType,
  iconName,
  attributes
}) => (
  <ProvenTextField
    type={type}
    name={name}
    required={required}
    label={label}
    color={color}
    disabled={disabled}
    error={error}
    placeholder={placeholder}
    value={value}
    isThereAnUnknownError={isThereAnUnknownError}
    iconType={iconType}
    iconName={iconName}
    {...attributes}
  />
)

Builder.registerComponent(TextField, {
  name: 'Form:Input',
  override: true,
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Fad6f37889d9e40bbbbc72cdb5875d6ca',
  inputs: [
    {
      name: 'type',
      type: 'text',
      enum: [
        'text',
        'number',
        'email',
        'url',
        'checkbox',
        'radio',
        'range',
        'date',
        'datetime-local',
        'search',
        'tel',
        'time',
        'file',
        'month',
        'week',
        'password',
        'color',
        'hidden'
      ],
      defaultValue: 'text'
    },
    {
      name: 'name',
      type: 'string',
      required: true,
      helperText: 'Every input in a form needs a unique name describing what it takes, e.g. "email"'
    },
    {
      name: 'placeholder',
      type: 'string',
      defaultValue: 'Hello there',
      helperText: 'Text to display when there is no value'
    },
    {
      name: 'required',
      type: 'boolean',
      helperText: 'Is this input required to be filled out to submit a form',
      defaultValue: false
    },
    { name: 'label', type: 'text' },
    { name: 'color', type: 'text', enum: brandTextColors },
    {
      name: 'iconType',
      type: 'text',
      enum: [TYPE_KIT, TYPE_SYSTEM, TYPE_TAB, TYPE_DASHBOARD, TYPE_SOCIAL]
    },
    { name: 'iconName', type: 'text' },
    { name: 'disabled', type: 'boolean' },
    { name: 'error', type: 'text' },
    { name: 'value', type: 'text', advanced: true },
    { name: 'isThereAnUnknownError', type: 'boolean', advanced: true }
  ],
  noWrap: true,
  static: true
})
