import { Builder } from '@builder.io/react'
import SocialShare from 'components/pages/blog/blog-post/social-share'

Builder.registerComponent(SocialShare, {
  name: 'Social share',
  inputs: [
    { name: 'url', type: 'url' },
    { name: 'title', type: 'text' }
  ]
})
