import { Builder } from '@builder.io/react'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import React from 'react'
import SephoraInStoreCallToAction from '../../../shared/sephora-in-store-call-to-action'
import useProvenWidth from '../../../../hooks/useProvenWidth'

export const SephoraInStoreCallToActionForBuilder = props => {
  const width = useProvenWidth()
  const isMobile = width === `xs` || width === `sm`

  return (
    <ProvenResponsiveContainer disableLeftPadding={isMobile} disableRightPadding={isMobile}>
      <SephoraInStoreCallToAction {...props} />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(SephoraInStoreCallToActionForBuilder, {
  name: 'Sephora In-Store Call To Action',
  inputs: [
    { name: 'title', type: 'richText' },
    { name: 'buttonTextEmailMe', type: 'text' },
    { name: 'lottieFileMobile', type: 'file', required: true },
    { name: 'lottieFileDesktop', type: 'file', required: true },
    { name: 'description', type: 'text' },
    { name: 'modalEmailTitle', type: 'text' },
    { name: 'modalEmailToc', type: 'richText' },
    {
      name: 'storeId',
      type: 'text',
      required: true
    },
    { name: 'yotpo_product', type: 'reference', model: 'yotpo-product' }
  ]
})
