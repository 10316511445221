import { Builder } from '@builder.io/react'
import RelatedPosts from 'components/pages/blog/builder-blog-post/related-posts'

Builder.registerComponent(RelatedPosts, {
  name: 'Related articles',
  inputs: [
    { name: 'title', type: 'text' },
    {
      name: 'blogposts',
      type: 'list',
      subFields: [{ name: 'post', type: 'reference', model: 'blog-post' }]
    }
  ]
})
