import React from 'react'
import { Builder, withChildren } from '@builder.io/react'
import ReactSlickSlider from 'react-slick'
import ProvenIcon from '../../../shared/proven-icon'
import {
  ICON_SIZES,
  TYPE_DASHBOARD,
  TYPE_KIT,
  TYPE_SOCIAL,
  TYPE_SYSTEM,
  TYPE_TAB
} from 'constants/icons'
import { brandTextColors } from 'styles/theme-proven/colors'
import { onDesktop } from '../../../../styles/theme-proven/responsive'
import { ARROW_VERSION_STYLE_A, ARROW_VERSION_STYLE_B } from '../../../../constants/constants'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const Slider = ({
  attributes,
  builderBlock: _builderBlock,
  builderState: _builderState,
  children,
  customPaging,
  dotsClass,
  prevArrowIconType,
  prevArrowIconName,
  nextArrowIconType,
  nextArrowIconName,
  arrowSize,
  arrowColor,
  arrowHoverColor,
  arrowOffset = 0,
  arrowVersionStyle = ARROW_VERSION_STYLE_A,
  ...props
}) => {
  const getArrowOffset = theme => {
    // Adapted from MUI Material SvgIcon.
    // https://github.com/mui/material-ui/blob/511b38515faa7766405cec114ffeda3b2077a4fc/packages/mui-material/src/SvgIcon/SvgIcon.js#L46-L51
    const iconSize = {
      inherit: 'inherit',
      small: theme.typography?.pxToRem?.(20) || '1.25rem',
      medium: theme.typography?.pxToRem?.(24) || '1.5rem',
      large: theme.typography?.pxToRem?.(35) || '2.1875rem'
    }[arrowSize]

    return `calc(-${iconSize} - ${arrowOffset}px)`
  }

  let leftStyle, rightStyle

  const useStyles = makeStyles({
    customDots: {
      '& li': {
        width: '13px'
      }
    }
  })
  const classes = useStyles()
  if (arrowVersionStyle === ARROW_VERSION_STYLE_B) {
    const arrowStyleB = {
      top: 'calc(100% + 24px)',
      width: '32px',
      height: '32px',
      padding: '8px',
      borderStyle: 'solid',
      borderColor: 'gray.middleGray',
      borderRadius: '50%',
      borderWidth: '1px',
      position: 'absolute',
      zIndex: '2',
      cursor: 'pointer',
      dotsClass: `slick-dots ${classes.customDots}`
    }
    leftStyle = {
      ...arrowStyleB,
      transform: 'translate(-100%,-50%)',
      left: 'calc(50% + 14px - 40px)'
    }
    rightStyle = {
      ...arrowStyleB,
      transform: 'translate(100%,-50%)',
      right: 'calc(50% - 14px - 40px)'
    }
  } else {
    const arrowSx = {
      cursor: 'pointer',
      top: '50%',
      transform: 'translate(0, -50%)',
      position: 'absolute',
      zIndex: '2'
    }
    leftStyle = { left: '-8px', ...arrowSx, [onDesktop]: { left: getArrowOffset } }
    rightStyle = { right: getArrowOffset, ...arrowSx }
  }

  const NextArrow = ({ onClick }) => (
    <ProvenIcon
      // Basically the same as .slick-next class CSS, but without the transparency.
      sx={rightStyle}
      type={nextArrowIconType}
      name={nextArrowIconName}
      size={arrowSize}
      color={arrowColor}
      hoverColor={arrowHoverColor}
      onClick={onClick}
    />
  )
  const PrevArrow = ({ onClick }) => (
    <ProvenIcon
      // Basically the same as .slick-prev class CSS, but without the transparency.
      sx={leftStyle}
      type={prevArrowIconType}
      name={prevArrowIconName}
      size={arrowSize}
      color={arrowColor}
      hoverColor={arrowHoverColor}
      onClick={onClick}
    />
  )

  // Annoyingly, we have to handle dotsClass individually. Passing anything other
  // than a non-zero length string with the correct class will mess up the dots
  // rendering.
  const dotsClassProp = {}

  if (dotsClass?.length > 0) dotsClassProp.dotsClass = dotsClass

  return (
    <Box sx={{ pb: 17, [onDesktop]: { pb: 0 } }}>
      <ReactSlickSlider
        {...attributes}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        {...dotsClassProp}
        {...props}
      >
        {children}
      </ReactSlickSlider>
    </Box>
  )
}

Builder.registerComponent(withChildren(Slider), {
  name: 'Slider',
  canHaveChildren: true,
  inputs: [
    { name: 'slidesToShow', type: 'number', required: true, defaultValue: 1 },
    { name: 'slidesToScroll', type: 'number', required: true, defaultValue: 1 },
    { name: 'initialSlide', type: 'number', defaultValue: 1 },
    { name: 'speed', type: 'number', required: true, defaultValue: 500 },
    { name: 'arrows', type: 'boolean', defaultValue: true },
    { name: 'infinite', type: 'boolean', defaultValue: true },
    { name: 'centerMode', type: 'boolean', defaultValue: false },
    { name: 'swipe', type: 'boolean', defaultValue: true },
    { name: 'dots', type: 'boolean', defaultValue: false },
    { name: 'variableWidth', type: 'boolean', defaultValue: false },
    {
      name: 'prevArrowIconType',
      type: 'text',
      enum: [TYPE_KIT, TYPE_SYSTEM, TYPE_TAB, TYPE_DASHBOARD, TYPE_SOCIAL],
      defaultValue: TYPE_SYSTEM
    },
    {
      name: 'arrowVersionStyle',
      type: 'text',
      enum: [ARROW_VERSION_STYLE_A, ARROW_VERSION_STYLE_B],
      defaultValue: ARROW_VERSION_STYLE_A
    },
    { name: 'prevArrowIconName', type: 'text', defaultValue: 'arrow-left' },
    {
      name: 'nextArrowIconType',
      type: 'text',
      enum: [TYPE_KIT, TYPE_SYSTEM, TYPE_TAB, TYPE_DASHBOARD, TYPE_SOCIAL],
      defaultValue: TYPE_SYSTEM
    },
    { name: 'nextArrowIconName', type: 'text', defaultValue: 'arrow-right' },
    { name: 'arrowSize', type: 'text', enum: ICON_SIZES },
    { name: 'arrowColor', type: 'text', enum: brandTextColors },
    { name: 'arrowHoverColor', type: 'text', enum: brandTextColors },
    { name: 'arrowOffset', type: 'number' },
    { name: 'dots', type: 'boolean', defaultValue: false },
    { name: 'dotsClass', type: 'text' },
    {
      name: 'responsive',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'breakpoint',
          type: 'number'
        },
        {
          name: 'settings',
          type: 'object',
          subFields: [
            {
              name: 'centerPadding',
              type: 'string'
            },
            {
              name: 'slidesToShow',
              type: 'number'
            }
          ]
        }
      ]
    },
    { name: 'customPaging', type: 'code', advanced: true }
  ]
})
