import React from 'react'
import { Box } from '@mui/material'
import { Builder, withChildren } from '@builder.io/react'
import { useHistory } from 'react-router-dom'

const AreaWithNavigation = withChildren(({ children, targetURL }) => {
  const history = useHistory()
  const handleClick = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    const isAbsoluteURL = /^(?:[a-z+]+:)?\/\//i.test(targetURL)

    if (isAbsoluteURL) {
      window.location.href = targetURL
    } else {
      history.push(targetURL)
    }
  }

  return <Box onClick={targetURL && handleClick}>{children}</Box>
})

export default AreaWithNavigation

Builder.registerComponent(AreaWithNavigation, {
  name: 'Area With Navigation',
  canHaveChildren: true,
  inputs: [{ name: 'targetURL', type: 'text', required: true }]
})
