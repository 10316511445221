import React from 'react'
import { Builder } from '@builder.io/react'
import { Text, textConfig } from './Text'

// DEPRECATED
// Text Rich was originally meant for blog posts, so we'll force the blogPost sx overrides.
Builder.registerComponent(props => <Text {...props} target="blogPost" />, {
  ...textConfig,
  name: 'Text Rich',
  inputs: textConfig.inputs.filter(input => input.name !== 'target' && input.name !== 'component'),
  hideFromInsertMenu: true
})
