import { Builder } from '@builder.io/react'
import React from 'react'
import YotpoStars from '../../../shared/yotpo-stars'

const formatYotpoProduct = yotpoProductFromBuilder => {
  const yp = yotpoProductFromBuilder?.value?.data

  if (!yp) {
    return null
  }

  const yotpo_product = {
    name: yp.name,
    sku_product_id: yp.productId === 'brand-all' ? null : yp.productId,
    url: yp.url,
    image_path: yp.imagePath
  }

  return yotpo_product
}
export const StarsYotpoForBuilder = props => {
  const yotpo_product = formatYotpoProduct(props?.yotpo_product)

  return (
    <YotpoStars
      sku_product_id={yotpo_product?.sku_product_id}
      name={yotpo_product?.name}
      url={yotpo_product?.url}
      image_url={yotpo_product?.image_path}
    />
  )
}

Builder.registerComponent(StarsYotpoForBuilder, {
  name: 'Stars Yotpo',
  inputs: [{ name: 'yotpo_product', type: 'reference', model: 'yotpo-product', required: true }]
})
