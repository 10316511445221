import React, { useContext, useEffect, useState } from 'react'
import { BuilderComponent } from '@builder.io/react'
import { ReactReduxContext } from 'react-redux'
import traverse from 'traverse'
import { waitForRef, waitForElement, waitForElements } from 'utils/content-js'
import { appEditionSelector, userInfoSelector } from 'utils/selectors'
import { APP_EDITION_SEPHORA, APP_EDITION_PROVEN } from 'constants/constants'
import { BuilderSharedDataContext } from 'providers/BuilderSharedDataProvider'
import { filterFaqInBuilderIoContext } from '../../utils/helpers/faqsHelper'
import { forceGetContentV2 } from '../../services/builder-helper'
import { isAuthenticated as checkIsAuthenticated } from 'utils/auth/auth'
import useAuth from '../../hooks/use-auth'

// Like <BuilderComponent>, but with our own shared context to be consumed
// by Builder content, symbols, etc.

// HACKS
//
// There's something weird going on with auth, so until we do a proper refactor to the auth system,
// the following hacks have been put in place:
//
//   - Grab Redux state directly from the store rather than with useSelector or mapStateToProps.
//   - The app will hang if you try to map userInfoSelector in mapStateToProps while also getting
//     the authentication status from useIsAuthenticated or the isAuthenticated auth action.
//   - You won't be able to log in using all manner of hooks that connect to the Redux store.
//
// Basically, anything that creates a connection to the Redux store gets messed up when logging in
// with our current auth system, which is based on a complex chain of thunks.
//
// See https://proven.atlassian.net/jira/software/c/projects/ENG/boards/42?modal=detail&selectedIssue=ENG-1221
// for more context.
//
// -Ersin

export const ProvenBuilderComponent = ({ context, data, reactOnlySharedData, ...props }) => {
  const { store } = useContext(ReactReduxContext)
  const state = store.getState()
  const appEdition = appEditionSelector(state)
  const userInfo = userInfoSelector(state)
  const { isAuthenticated: isAuthenticatedHook } = useAuth()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const checkAuthStatus = async () => {
    const authenticated = await checkIsAuthenticated()
    setIsAuthenticated(authenticated)
  }

  useEffect(() => {
    checkAuthStatus()
  }, [])

  useEffect(() => {
    if (isAuthenticatedHook !== isAuthenticated && isAuthenticatedHook === false) {
      // fix case when user is logged in and then logged out
      // need to refresh the pdp
      checkAuthStatus()
    }
  }, [isAuthenticatedHook])

  const sharedContext = {
    constants: { APP_EDITION_PROVEN, APP_EDITION_SEPHORA },
    // pages/pdps use state.shared.meta.appEdition
    meta: { appEdition },
    user: {
      isAuthenticated, //It could look redundant to pass isAuthenticated in both state and context, but it's needed to pass it in context, since the state passed from here is not accessible from inside builderio symbols
      info: { firstName: userInfo.firstName }
    },
    utils: {
      traverse,
      filterFaqInBuilderIoContext,
      waitForElement,
      waitForElements,
      waitForRef,
      forceGetContentV2
    }
  }
  //TODO validate if we can pass the data only through context instead of using state, in order to pass data in the same way for both pages and symbols, in that case we'd need to refactor every page (page, pdp, etc) (not symbols) in builderIO where we get the data from state, by getting it from context
  const sharedState = {
    meta: { appEdition },
    user: { isAuthenticated }
  }

  //as long as the object is small, it should be ok using JSON.stringify here
  const builderComponentKey = JSON.stringify({
    ...sharedContext,
    utils: undefined,
    contentId: props?.content?.id
  }) //it's a workaround to force builderio to refresh symbols when sharedContext changes (the object passed through BuilderComponent's 'data' prop  is not accessible from inside symbols)
  //(it worked neither using cache:false nor inlineContent)

  return (
    <BuilderSharedDataContext.Provider value={{ reactOnly: reactOnlySharedData }}>
      {/* eslint-disable-next-line local-rules/use-proven-builder-component */}
      <BuilderComponent
        key={builderComponentKey}
        context={{
          ...context,
          shared: sharedContext
        }}
        data={{
          ...data,
          shared: sharedState
        }}
        {...props}
      />
    </BuilderSharedDataContext.Provider>
  )
}
