import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import { Builder } from '@builder.io/react'
import React from 'react'
import OneTimeAddons from '../../../shared/one-time-addons'
import useAuth from '../../../../hooks/use-auth'
import { useSelector } from 'react-redux'
import { activeSubscriptionsSelect } from '../../../../utils/selectors'

export const AccessoriesUpsell = props => {
  const activeSubscriptions = useSelector(state => activeSubscriptionsSelect(state))
  const { isAuthenticated } = useAuth()
  return (
    <ProvenResponsiveContainer fitSidePaddingsForDesktopDashboard={true}>
      {isAuthenticated && !!activeSubscriptions?.length && (
        <OneTimeAddons {...props} prefix_url={'/account/shop/onetime'} />
      )}
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(AccessoriesUpsell, {
  name: 'Accessories Upsell',
  inputs: [
    {
      name: 'title',
      type: 'text',
      defaultValue: 'One-time Add-ons',
      helperText: `Title. Default value: 'One-time Add-ons'`
    },
    {
      name: 'paragraph',
      type: 'text',
      defaultValue: 'Ships one time with your upcoming shipment',
      helperText: `Paragraph. Default value: 'Ships one time with your upcoming shipment'`
    }
  ]
})
