import React, { useRef, useState, useEffect } from 'react'
import { Builder } from '@builder.io/react'
import BeforeAfterSlide from '../../../shared/before-after-carousel/slide'
import { forceGetContentV2 } from '../../../../services/builder-helper'

const DEFAULT_IMAGE = 'https://placehold.co/40x40'

const BeforeAfterCard = ({ beforeAfterSlide }) => {
  const [data, setData] = useState(
    beforeAfterSlide?.data?.data || beforeAfterSlide?.value?.data || null
  )
  const prevSlide = useRef(null)
  const fetching = useRef(false)

  useEffect(() => {
    const execute = async () => {
      let res

      try {
        prevSlide.current = beforeAfterSlide
        fetching.current = true
        const finalOptions = {
          noTargeting: true,
          query: {
            id: beforeAfterSlide?.id
          }
        }
        const results = await forceGetContentV2('before-after-slide', finalOptions)
        res = results[0].data
      } catch (err) {
        throw new Error("Couldn't fetch slide data for before after slide.", { cause: err })
      }

      setData(res)
      fetching.current = false
    }

    if (beforeAfterSlide?.id !== prevSlide.current?.id) {
      if (beforeAfterSlide?.id) {
        if (beforeAfterSlide?.data || beforeAfterSlide?.value) {
          prevSlide.current = beforeAfterSlide
          setData(beforeAfterSlide?.data?.data || beforeAfterSlide?.value?.data)
        } else if (!fetching.current) execute()
      } else {
        prevSlide.current = beforeAfterSlide
        setData(null)
      }
    }
  }, [beforeAfterSlide, beforeAfterSlide?.id])

  return (
    <BeforeAfterSlide
      name={data?.name || 'Lorem Ipsum'}
      imgBefore={data?.imgBefore || DEFAULT_IMAGE}
      imgAfter={data?.imgAfter || DEFAULT_IMAGE}
      quote={data?.quote || 'Lorem Ipsum'}
      tags={data?.tags || []}
    />
  )
}

Builder.registerComponent(BeforeAfterCard, {
  name: 'Before After Card',
  inputs: [
    {
      name: 'beforeAfterSlide',
      type: 'reference',
      model: 'before-after-slide'
    }
  ]
})
