import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getQueryStringValue } from 'utils/helpers'
import useAuth from 'hooks/use-auth'
import Loading from '../../../shared/loading'

export default function AuthUserPasswordless() {
  const history = useHistory()
  const { verifyPasswordlessAuth } = useAuth()
  const code = getQueryStringValue('code')
  const email = getQueryStringValue('email')
  const token = getQueryStringValue('token')
  const verifyTokenOrCode = useCallback(async () => {
    try {
      const isMagicLinkAttempt = token && email
      if (!isMagicLinkAttempt)
        throw new Error(
          'token+email combination query strings are required for passwordless authentication.'
        )
      await verifyPasswordlessAuth({ email, token })
    } catch (err) {
      console.error(err.message)
      history.push({ pathname: '/', state: { loginOpen: true } })
    }
  }, [])
  useEffect(() => {
    verifyTokenOrCode()
  }, [])

  return <Loading pastDelay></Loading>
}
