import { Builder } from '@builder.io/react'
import ProvenIcon from 'components/shared/proven-icon'
import { brandTextColors } from 'styles/theme-proven/colors'
import {
  ICON_SIZES,
  TYPE_DASHBOARD,
  TYPE_KIT,
  TYPE_SOCIAL,
  TYPE_SYSTEM,
  TYPE_TAB
} from 'constants/icons'

Builder.registerComponent(ProvenIcon, {
  name: 'Icon',
  inputs: [
    {
      name: 'type',
      type: 'text',
      enum: [TYPE_KIT, TYPE_SYSTEM, TYPE_TAB, TYPE_DASHBOARD, TYPE_SOCIAL],
      defaultValue: TYPE_SYSTEM
    },
    { name: 'name', type: 'text', defaultValue: 'alert' },
    { name: 'size', type: 'text', enum: ICON_SIZES },
    { name: 'color', type: 'text', enum: brandTextColors },
    { name: 'hoverColor', type: 'text', enum: brandTextColors }
  ]
})
