import { Builder } from '@builder.io/react'
import CallToAction from '../../../shared/call-to-action'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import React from 'react'
import { EYE_CREAM_PRODUCT, SYSTEM_PRODUCT } from '../../../../constants/products'

export const CallToActionForBuilder = props => {
  console.log('BuilderIO CTA props: ', props)
  return (
    <ProvenResponsiveContainer notUseDesktopPadding>
      <CallToAction {...props} />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(CallToActionForBuilder, {
  name: 'Call To Action',
  inputs: [
    { name: 'title', type: 'text' },
    { name: 'subTitle', type: 'text' },
    { name: 'buttonText', type: 'text' },
    { name: 'product', type: 'text', enum: [SYSTEM_PRODUCT, EYE_CREAM_PRODUCT] },
    { name: 'showPrice', type: 'boolean', defaultValue: true }
  ]
})
