import React from 'react'
import { Button as MuiButton } from '@mui/material'
import { Builder } from '@builder.io/react'
import { useHistory } from 'react-router-dom'
import { trackEvent } from '../../../../utils/analytics'

const Button = ({ text, color, variant, targetURL, trackEventId }) => {
  const history = useHistory()
  const handleClick = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    const isAbsoluteURL = /^(?:[a-z+]+:)?\/\//i.test(targetURL)

    if (trackEventId) trackEvent(trackEventId)

    if (isAbsoluteURL) {
      window.location.href = targetURL
    } else {
      history.push(targetURL)
    }
  }

  return (
    <MuiButton color={color} variant={variant} onClick={targetURL && handleClick}>
      {text}
    </MuiButton>
  )
}

export const builderConfig = {
  inputs: [
    { name: 'text', type: 'text', defaultValue: 'GET YOUR FORMULA' },
    { name: 'color', type: 'text', enum: ['primary', 'secondary'], defaultValue: 'secondary' },
    { name: 'fullWidth', type: 'boolean', defaultValue: false },
    {
      name: 'variant',
      type: 'text',
      enum: ['contained', 'outlined', 'text', 'icon', 'link'],
      defaultValue: 'contained'
    },
    { name: 'targetURL', type: 'text' },
    { name: 'trackEventId', type: 'text' }
  ]
}

export default Button

Builder.registerComponent(Button, {
  name: 'Button',
  ...builderConfig
})
