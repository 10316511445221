import { Builder } from '@builder.io/react'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import React from 'react'
import ConcernsEnvironmentLifestyleWrapper from '../../../shared/concerns-environment-lifestyle-wrapper'

export const ConcernsEnvironmentLifestyle = props => {
  return (
    <ProvenResponsiveContainer>
      <ConcernsEnvironmentLifestyleWrapper {...props} />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(ConcernsEnvironmentLifestyle, {
  name: 'Concerns Environment And Lifestyle',
  inputs: [
    {
      name: 'enforceAccordionVersionForConcernsSection',
      type: 'boolean',
      defaultValue: false,
      helperText: `If it's true, it will show the accordion version for Concerns section no matter the app edition value`
    },
    {
      name: 'skinConcernsDescription',
      type: 'text',
      defaultValue:
        'Your 3-Step System is tailor-made to your skin’s needs. Powerhouse ingredients are carefully chosen to deliver the best results for you based on your unique skin concerns.',
      helperText: `Description shown below 'Based on Skin Concerns' title`
    },
    {
      name: 'environmentDescription',
      type: 'text',
      defaultValue:
        'Your environment has a major impact on your skin. Your personalized system accounts for the UV index, pollution type & level, water hardness and humidity of your municipality.',
      helperText: `Description shown below 'Made for Your Environment`
    },
    {
      name: 'lifeStyleDescription',
      type: 'text',
      defaultValue:
        'Your PROVEN system is formulated to complement your lifestyle, including your stress level, travel, blue light exposure, sleep amount & diet.',
      helperText: `Description shown below 'Based on Your Style of Life`
    }
  ]
})
