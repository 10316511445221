import React from 'react'
import { Builder, withChildren } from '@builder.io/react'
import { Button, Tooltip as MuiTooltip, Typography } from '@mui/material'
import ProvenIcon from 'components/shared/proven-icon'

export const Tooltip = withChildren(props => {
  const { header, description, link, children } = props
  return (
    <MuiTooltip
      enterTouchDelay={1}
      leaveTouchDelay={3000}
      {...props}
      componentsProps={{ tooltip: { sx: { maxWidth: '400px', mx: 2.5 } } }}
      title={
        <React.Fragment>
          {header && (
            <Typography variant="title2" mb={2} color="gray.elysian" component="p">
              {header}
            </Typography>
          )}
          <Typography variant="body2">{description}</Typography>
          {link && (
            <Button
              variant="text"
              color="primary"
              href={link}
              target="_blank"
              sx={{ px: 0, width: 'max-content' }}
              endIcon={<ProvenIcon type="system" name="arrow-right" />}
            >
              Learn More
            </Button>
          )}
        </React.Fragment>
      }
    >
      <div>{children}</div>
    </MuiTooltip>
  )
})

Builder.registerComponent(Tooltip, {
  name: 'Tooltip',
  canHaveChildren: true,
  inputs: [
    {
      name: 'header',
      type: 'text',
      defaultValue: 'Tooltip header...'
    },
    {
      name: 'description',
      type: 'text',
      defaultValue: 'Tooltip description...'
    },
    {
      name: 'link',
      type: 'text'
    },
    {
      name: 'placement',
      type: 'text',
      enum: ['bottom', 'top', 'left', 'right'],
      defaultValue: 'bottom'
    }
  ]
})
