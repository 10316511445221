import { Builder } from '@builder.io/react'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import React from 'react'
import EyecreamDuoWaitlistSection from '../../../shared/eyecream-duo-waitlist'

export const EyecreamDuoWaitlistSectionForBuilder = props => {
  return (
    <ProvenResponsiveContainer>
      <EyecreamDuoWaitlistSection {...props} />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(EyecreamDuoWaitlistSectionForBuilder, {
  name: 'Eyecream Duo Waitlist',
  inputs: [
    { name: 'title1', type: 'text' },
    { name: 'title2', type: 'text' },
    { name: 'description', type: 'text' },
    { name: 'notes', type: 'text' },
    {
      name: 'images',
      type: 'list',
      subFields: [{ name: 'image', type: 'text' }]
    }
  ]
})
