import { Builder } from '@builder.io/react'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import React from 'react'
import ReviewsYotpo from '../../../shared/reviews-yotpo'

const formatYotpoProduct = yotpoProductFromBuilder => {
  const yp = yotpoProductFromBuilder?.value?.data

  if (!yp) {
    return null
  }

  const yotpo_product = {
    name: yp.name,
    product_id: yp.productId,
    url: yp.url,
    image_path: yp.imagePath
  }

  return yotpo_product
}
export const ReviewsYotpoForBuilder = props => {
  const yotpo_product = formatYotpoProduct(props?.yotpo_product)

  return (
    <ProvenResponsiveContainer>
      <ReviewsYotpo {...props} yotpo_product={yotpo_product} />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(ReviewsYotpoForBuilder, {
  name: 'Reviews Yotpo',
  inputs: [
    { name: 'title', type: 'text' },
    { name: 'yotpo_product', type: 'reference', model: 'yotpo-product', required: true },
    { name: 'backgroundColor', type: 'text', helperText: 'background color in hexa' }
  ]
})
