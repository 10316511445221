import React from 'react'
import { Builder, withChildren } from '@builder.io/react'
import Slider from '../../../shared/before-after-carousel/slider'
import { ARROW_VERSION_STYLE_A, ARROW_VERSION_STYLE_B } from '../../../../constants/constants'

const BeforeAfterSlider = ({ children, arrowVersionStyle }) => {
  return <Slider arrowVersionStyle={arrowVersionStyle}>{children}</Slider>
}

Builder.registerComponent(withChildren(BeforeAfterSlider), {
  name: 'Before After Slider',
  canHaveChildren: true,
  inputs: [
    {
      name: 'arrowVersionStyle',
      type: 'text',
      enum: [ARROW_VERSION_STYLE_A, ARROW_VERSION_STYLE_B],
      defaultValue: ARROW_VERSION_STYLE_A
    }
  ]
})
