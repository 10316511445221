import React from 'react'
import { Builder } from '@builder.io/react'
import Evolution from '../../../shared/evolution'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'

export const EvolutionForBuilder = props => {
  return (
    <ProvenResponsiveContainer>
      <Evolution {...props} />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(EvolutionForBuilder, {
  name: 'Evolution',
  inputs: []
})
