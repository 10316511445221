import { Builder } from '@builder.io/react'
import EditorsPicks from 'components/pages/blog/editors-picks'

Builder.registerComponent(EditorsPicks, {
  name: 'Editors Picks',
  inputs: [
    { name: 'title', type: 'text' },
    {
      name: 'editorsPicksPosts',
      type: 'list',
      subFields: [{ name: 'post', type: 'reference', model: 'blog-post' }]
    }
  ]
})
