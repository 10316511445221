import React from 'react'
import { Builder } from '@builder.io/react'
import WelcomeBackLogin from '../../../pages/welcome-back'

const WelcomeBackLoginForm = ({ title, subTitle }) => (
  <WelcomeBackLogin title={title} subTitle={subTitle} />
)

Builder.registerComponent(WelcomeBackLoginForm, {
  name: 'Welcome Back Login Form',
  inputs: [
    {
      name: 'title',
      type: 'text',
      defaultValue: 'Login to PROVEN',
      required: true
    },
    {
      name: 'subTitle',
      type: 'text',
      defaultValue:
        'Please enter your email. We will send you an email with a link to view your PROVEN profile.',
      required: false
    }
  ]
})
